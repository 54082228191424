import {
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Guide, Metadata } from '@sales-libs/sc-onboarding/data-access';
import { Languages } from '@sales-libs/shared/data-access/sc-generated';
import { ScSharedActions, ScSharedSelectors } from '@sales-libs/shared/feature';
import {
  Analytics,
  MetaTagsService,
  formatUrlFriendlyName,
  populateProductLineEnum,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'sl-sc-general-onboarding',
  templateUrl: './general-onboarding.component.html',
  styleUrls: ['./general-onboarding.component.scss'],
})
export class SlScGeneralOnboardingComponent implements OnInit, OnDestroy {
  @Output() languageSelectionChange: EventEmitter<string> = new EventEmitter();
  userGuid: string;
  userGuides$: Observable<Guide[]>;
  supportedLanguages$: Observable<Languages>;
  isLanguageSupported: boolean;
  selectedLanguage: { code: string; display_name: string };
  urlLanguageCode: string;
  defaultLanguage = {
    code: 'EN',
    english_name: 'English',
    native_name: 'English',
  };
  firstSectionName: string;
  loadingButton: { [id: number]: boolean } = {};
  isButtonDisabled: boolean;
  generalMetadata$: Observable<Metadata>;

  data = [
    {
      icon: 'help',
      title: 'turnkey_solution.onboarding.step_1.title',
      description: 'turnkey_solution.onboarding.step_1.description',
    },
    {
      icon: 'local_shipping',
      title: 'turnkey_solution.onboarding.step_2.title',
      description: 'turnkey_solution.onboarding.step_2.description',
    },
    {
      icon: 'build',
      title: 'turnkey_solution.onboarding.step_3.title',
      description: 'turnkey_solution.onboarding.step_3.description',
    },
  ];

  productLineEnum: { [key: string]: string } = {};

  readonly Analytics = Analytics;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private metaTagsService: MetaTagsService,
    private _ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.getUrlLanguageCodeParam();
    this.getGuides();
    this.getProductLineEnum();
    this.setupLanguages();
    this.setPageMetadata();
  }

  getUrlLanguageCodeParam(): void {
    this.route?.params.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.urlLanguageCode = event.language?.toUpperCase();
    });
  }

  setupLanguages(): void {
    this.getLanguages();
    this.preselectLanguageAndFetchData();
  }

  getLanguages() {
    this.store.dispatch(ScSharedActions.getLanguages({}));
    this.supportedLanguages$ = this.store.pipe(
      select(ScSharedSelectors.languages),
      filterTruthy(),
    );
  }

  preselectLanguageAndFetchData(): void {
    const browserLanguage =
      this.urlLanguageCode ??
      window.navigator.language.slice(0, 2).toUpperCase();

    this.supportedLanguages$
      .pipe(takeUntil(this.destroy$))
      .subscribe((languages) => {
        if (languages.data)
          this.isLanguageSupported = languages.data?.some(
            (language) => language.code === browserLanguage,
          );

        this.selectedLanguage = {
          code: this.isLanguageSupported
            ? browserLanguage
            : this.defaultLanguage.code,
          display_name:
            languages.data?.find(
              (language) => language.code === browserLanguage,
            )?.native_name ?? this.defaultLanguage.native_name,
        };

        this.changeUserLanguage(
          this.selectedLanguage.code,
          this.selectedLanguage.display_name,
        );
      });
  }

  getGuides(): void {
    this.userGuides$ = this.store.pipe(
      select(ScSharedSelectors.userGuides),
      filterTruthy(),
    );
  }

  getProductLineEnum(): void {
    this.userGuides$.pipe(takeUntil(this.destroy$)).subscribe((guides) => {
      this.productLineEnum = populateProductLineEnum(guides, false);
    });
  }

  setPageMetadata() {
    this.store
      .pipe(select(ScSharedSelectors.generalMetadata), filterTruthy(), take(1))
      .subscribe((metadata) => {
        this.metaTagsService.setMetaTitleDescriptionKeywords(
          metadata.title,
          metadata.description,
          metadata.keywords,
        );
      });
  }

  changeUserLanguage(
    selectedLanguageCode: string,
    selectedLanguageName: string,
  ) {
    if (
      selectedLanguageCode &&
      selectedLanguageCode !== this.translateService.currentLang
    ) {
      const languageCode = selectedLanguageCode.toLowerCase();

      this.translateService.use(languageCode);
      this.selectedLanguage = {
        code: languageCode,
        display_name: selectedLanguageName,
      };
      this.languageSelectionChange.emit(this.selectedLanguage.code);

      this._ngZone.run(() => {
        this.router.navigate(['/', languageCode]);
      });
    }
  }

  startConfigurator(guide: Guide): void {
    this.loadingButton[guide.id] = true;
    this.isButtonDisabled = true;

    this.updateLocalStorage();

    this.changeUserLanguage(
      this.selectedLanguage?.code,
      this.selectedLanguage?.display_name,
    );

    const productLineName = formatUrlFriendlyName(guide.name);
    const languageCode = this.selectedLanguage?.code.toLowerCase();
    this._ngZone.run(() => {
      this.router.navigateByUrl(`/${languageCode}/${productLineName}`);
    });
  }

  updateLocalStorage(): void {
    localStorage.removeItem('selectedCountry');
    localStorage.removeItem('selectedRegion');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
