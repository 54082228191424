import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Languages } from '@sales-libs/shared/data-access/sc-generated';
import { ScSharedActions, ScSharedSelectors } from '@sales-libs/shared/feature';
import { populateUrlFriendlyProductLineEnum } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SlScGeneralOnboardingGuard {
  private readonly defaultLanguageCode = 'EN';
  defaultLanguage = {
    code: 'EN',
    english_name: 'English',
    native_name: 'English',
  };
  supportedLanguages$: Observable<Languages>;
  isLanguageSupported: boolean;

  constructor(
    private router: Router,
    private store: Store,
    private destroyRef: DestroyRef,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const environment = route.data.environment;
    const urlLanguageParam = route.paramMap.get('language');

    this.store.dispatch(
      ScSharedActions.getUserGuides({
        language: urlLanguageParam ?? this.defaultLanguage.code,
      }),
    );

    return this.store.pipe(
      select(ScSharedSelectors.userGuides),
      filterTruthy(),
      take(1),
      switchMap((guides) => {
        if (
          guides.every((guide) => guide.settings?.display_maintenance_page) ===
          true
        ) {
          this.navigateToMaintenancePage();
          return [false];
        } else if (
          environment.FEATUREFLAGS.enable_general_onboarding_screen === 'false'
        ) {
          this.navigateToProductLineOnboardingPage(guides);
          return [false];
        } else {
          return [true];
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  navigateToProductLineOnboardingPage(guides) {
    const urlFriendlyProductLineEnum =
      populateUrlFriendlyProductLineEnum(guides);
    this.router.navigateByUrl(
      `/${this.defaultLanguageCode}/${urlFriendlyProductLineEnum.TailLift}`,
    );
  }

  navigateToMaintenancePage() {
    this.router.navigateByUrl('/maintenance');
  }
}
