import { createAction, props } from '@ngrx/store';
import {
  Guid,
  ModelError,
  Sections,
} from '@sales-libs/sc-onboarding/data-access';

export namespace SlScOnboardingActions {
  export const getSections = createAction(
    '[Onboarding] GET_SECTIONS',
    props<{ guideId: number; language: string }>(),
  );
  export const getSectionsSuccess = createAction(
    '[Onboarding] GET_SECTIONS_SUCCESS',
    props<{ sections: Sections }>(),
  );
  export const getSectionsError = createAction(
    '[Onboarding] GET_SECTIONS_ERROR',
    props<{ payload: ModelError }>(),
  );

  export const createUserGuid = createAction(
    '[Onboarding] GET_USER_GUID',
    props<{ guideId: number; language: string }>(),
  );
  export const createUserGuidSuccess = createAction(
    '[Onboarding] GET_USER_GUID_SUCCESS',
    props<{ personal_guide: Guid }>(),
  );
  export const createUserGuidError = createAction(
    '[Onboarding] GET_USER_GUID_ERROR',
    props<{ payload: ModelError }>(),
  );

  export const resetState = createAction('[Onboarding] RESET_STATE');
}
