import {
  Guid,
  Guides,
  Languages,
  Sections,
} from '@sales-libs/sc-onboarding/data-access';

export interface SlScOnboardingState {
  userGuides?: Guides;
  languages?: Languages;
  sections: Sections;
  userGuid?: Guid;
}

export const initialState: SlScOnboardingState = {
  userGuides: undefined,
  languages: undefined,
  sections: {} as Sections,
  userGuid: undefined,
};
